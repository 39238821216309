/*global google*/
import React from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import Routing from './components/Routing';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import {
  setIpInfo,
  getIpInfo,
  setState
} from './components/redux/Sites/SitesAction';
import { getLocation, findAddress } from './components/utils/funcs';

function App() {
  const dispatch = useDispatch();
  const ipInfo = useSelector(getIpInfo, shallowEqual);

  React.useEffect(() => {
    dispatch(setIpInfo());
    getLocation(
      (position) => {
        console.log(position.coords.latitude, position.coords.longitude);
        dispatch(
          setState({
            geoLocation: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
              error: null
            }
          })
        );
        // findAddress(
        //   position.coords.latitude,
        //   position.coords.longitude,
        //   (place) => {
        //     console.log('place is ', place);
        //   }
        // );
      },
      (err) => {
        console.log(err.message);
        dispatch(
          setState({
            geoLocation: {
              lat: '',
              lng: '',
              error: err.message
            }
          })
        );
      }
    );
  }, [dispatch]);
  return <Routing ipInfo={ipInfo} />;
}

export default App;
