import React, { Suspense } from 'react';
import { BrowserRouter, Navigate, Routes, Route } from 'react-router-dom';
import Loading from './utils/Loading';
import ScrollToTop from './utils/ScrollToTop';
import MyErrorBoundary from './utils/MyErrorBoundary';

const Layout = React.lazy(() => import('./Layouts/Layout'));
const LayoutUnAuth = React.lazy(() => import('./Layouts/LayoutUnAuth'));
const Home = React.lazy(() => import('./Home'));
const NotFound = React.lazy(() => import('./NotFound/NotFound'));
const Login = React.lazy(() => import('./auth/Login'));
const Register = React.lazy(() => import('./auth/Register'));
const Forgot = React.lazy(() => import('./auth/Forgot'));
const Sites = React.lazy(() => import('./redux/Sites/Sites'));
const AddPost = React.lazy(() => import('./Posts/AddPost'));
const Details = React.lazy(() => import('./Details/Details'));
const SinglePost = React.lazy(() => import('./SinglePost/SinglePost'));

function Routing() {
  return (
    <MyErrorBoundary>
      <Suspense fallback={<Loading />}>
        <BrowserRouter>
          <ScrollToTop>
            <Routes>
              <Route path="/auth" element={<LayoutUnAuth />}>
                <Route path="login" element={<Login />} />
                <Route path="register" element={<Register />} />
                <Route path="forgot" element={<Forgot />} />
              </Route>
              <Route path="/" element={<Layout />}>
                <Route path="" element={<Home />} />
                <Route path="details/:serviceType" element={<Details />} />
                <Route path="view/:id" element={<SinglePost />} />
                <Route path="posts/add/:serviceType" element={<AddPost />} />
                <Route path="sites" element={<Sites />} />
                <Route path="notfound" element={<NotFound />} />
                <Route path="*" element={<Navigate replace to="/notfound" />} />
              </Route>
            </Routes>
          </ScrollToTop>
        </BrowserRouter>
      </Suspense>
    </MyErrorBoundary>
  );
}

export default Routing;
