import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import reducer from './rootReducer';

const middleware = [thunk];
if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
  middleware.push(logger);
}

const store = configureStore({
  reducer,
  middleware
});

export default store;
