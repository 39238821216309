import { ipInfo } from '../../api/auth';

/** */
export const setName = (name) => {
  return (dispatch) => {
    console.log('in action: name is ', name);
    dispatch({ type: 'SET_NAME', payload: name });
  };
};

export const setAge = (age) => {
  return (dispatch) => {
    dispatch({ type: 'SET_AGE', payload: age });
  };
};

export const getName = (state) => {
  return state.SitesReducer.name;
};

export const getAge = (state) => {
  return state.SitesReducer.age;
};

export const selectedCity = (details) => {
  return (dispatch) => {
    dispatch({ type: 'SET_SELECTED_CITY', payload: details });
  };
};

export const getSelectedCity = (state) => {
  return state.SitesReducer.selectedCity;
};

export const setState = (data) => {
  return (dispatch) => {
    dispatch({ type: 'SET_STATE', payload: data });
  };
};

export const setError = (message) => {
  return (dispatch) => {
    dispatch({ type: 'SET_ERROR', payload: message });
  };
};

export const setLoading = (val) => {
  return (dispatch) => {
    dispatch({ type: 'SET_LOADING', payload: val });
  };
};

export const getError = (state) => {
  return state.SitesReducer.error;
};

export const getLoading = (state) => {
  return state.SitesReducer.loading;
};

export const setIpInfo = () => {
  return async (dispatch) => {
    const r = await ipInfo();
    dispatch({
      type: 'SET_IP_INFO',
      payload: {
        ...r.result,
        lat: parseFloat(r.result.lat),
        lng: parseFloat(r.result.lng)
      }
    });
  };
};

export const getIpInfo = (state) => {
  return state.SitesReducer.ipInfo;
};
