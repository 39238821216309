/** */
import axios from '../utils/myAxios';

export const register = async (data) => {
  const url = '/auth/register.php';

  const result = await axios.post(url, data);
  const res = result?.data;

  return res;
};

export const login = async (data) => {
  const url = '/auth/login.php';

  const result = await axios.post(url, data);
  const res = result?.data;

  return res;
};

export const ipInfo = async () => {
  const ip = process.env.NODE_ENV === 'production' ? '' : '76.132.246.90';
  const url = `/auth/ip_add.php?ip=${ip}`;

  const result = await axios.get(url);
  const res = result?.data;

  window.localStorage.setItem('ipInfo', JSON.stringify(res));
  return res;
};
