/*global google*/
import Cookies from 'js-cookie';

export const clearSessionStorage = () => {
  localStorage.removeItem(`Parse/appId/currentUser`);
  Cookies.remove('session.sig');
  Cookies.remove('session');
};

export const prefixZero = (val) => {
  const str = val.toString().length === 1 ? `0${val}` : val;
  return str;
};

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

export const getMonth = (val) => {
  return months[val - 1];
};

export const utubeIDGrabber = (url) => {
  const regExp =
    /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/|shorts\/|live\/)|(?:(?:watch)?\?v(?:i)?=|\&v(?:i)?=))([^#\&\?]*).*/;
  const match = url.match(regExp);
  const id = match && match[1].length === 11 ? match[1] : false;
  return id;
  /*var ID = '';
  url = url
    .replace(/(>|<)/gi, '')
    .split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);
  if (url[2] !== undefined) {
    ID = url[2].split(/[^0-9a-z_-]/i); //ID = url[2].split(/[^0-9a-z_\-]/i);
    ID = ID[0];
  } else if (url[0] !== undefined) {
    ID = url[0];
  } else {
    ID = url;
  }

  console.log('id is ', ID);
  return ID;*/
};

export const percentage = (partialValue, totalValue) => {
  return (100 * partialValue) / totalValue;
};

export const parsePlaces = (place) => {
  const componentForm = {
    locality: 'long_name',
    administrative_area_level_1: 'short_name',
    administrative_area_level_2: 'long_name',
    country: 'short_name',
    street_number: 'long_name',
    route: 'long_name',
    postal_code: 'long_name',
    postal_code_suffix: 'long_name'
  };

  const obj = {};
  obj['place_id'] = place.place_id;
  let val2 = '';
  obj.formatted_address = place.formatted_address;
  obj.lat = place.geometry.location.lat();
  obj.lng = place.geometry.location.lng();

  for (let elm of place.address_components) {
    let addressType = elm.types[0];
    if (componentForm[addressType]) {
      const val = elm[componentForm[addressType]];
      if (addressType === 'locality') {
        addressType = 'city';
      } else if (addressType === 'administrative_area_level_1') {
        addressType = 'state';
        val2 = elm['long_name'];
        obj['state_long'] = val2;
      } else if (addressType === 'administrative_area_level_2') {
        addressType = 'county';
      } else if (addressType === 'country') {
        val2 = elm['long_name'];
        obj['country_long'] = val2;
      }
      obj[addressType] = val;
    }
  }

  return obj;
};

export const getLocation = (showPosition, showError) => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(showPosition, showError);
  } else {
    console.log('Geolocation is not supported by this browser.');
  }
};

export const conversionPlace = (place) => {
  const objConversion = {
    formatted_address: place.formatted_address,
    lat: place.geometry.location.lat(),
    lng: place.geometry.location.lng(),
    address: place.formatted_address,
    place_id: place.place_id,
    city: '',
    county: '',
    state: '',
    country: '',
    street_number: '',
    route: '',
    neighborhood: '',
    postal_code: '',
    postal_code_suffix: ''
  };
  const componentForm = {
    street_number: 'short_name',
    route: 'long_name',
    neighborhood: 'long_name',
    locality: 'long_name',
    administrative_area_level_2: 'long_name',
    administrative_area_level_1: 'short_name',
    country: 'short_name',
    postal_code: 'short_name',
    postal_code_suffix: 'short_name'
  };
  let val2 = '';
  for (let elm of place.address_components) {
    let addressType = elm.types[0];
    if (!componentForm[addressType]) {
      continue;
    }
    const key = componentForm[addressType];
    const val = elm[key];
    if (addressType === 'locality') {
      addressType = 'city';
    } else if (addressType === 'administrative_area_level_1') {
      addressType = 'state';
      val2 = elm['long_name'];
      objConversion['state_long'] = val2;
    } else if (addressType === 'administrative_area_level_2') {
      addressType = 'county';
    } else if (addressType === 'country') {
      val2 = elm['long_name'];
      objConversion['country_long'] = val2;
    }

    objConversion[addressType] = val;
  }
  return objConversion;
};

export const findAddress = (l1, l2, callback) => {
  const geocoder = new google.maps.Geocoder();
  const latlng = new google.maps.LatLng(l1, l2);
  geocoder.geocode({ latLng: latlng }, function (results, status) {
    if (status !== google.maps.GeocoderStatus.OK) {
      return;
    }
    let place = results[0];
    const convPlace = conversionPlace(place);
    callback({ ...convPlace });
  });
};
