// Initial state values
const initialState = {
  name: '',
  age: 0,
  selectedCity: null,
  error: null,
  loading: false,
  ipInfo: null,
  geoLocation: {
    lat: '',
    lng: '',
    error: null
  }
};

// obj with key value pair
const obj = {
  SET_IP_INFO: (state, action) => {
    return { ...state, ipInfo: action.payload };
  },
  SET_NAME: (state, action) => {
    return { ...state, name: action.payload };
  },
  SET_AGE: (state, action) => {
    return { ...state, age: action.payload };
  },
  SET_SELECTED_CITY: (state, action) => {
    return { ...state, selectedCity: action.payload };
  },
  SET_ERROR: (state, action) => {
    return { ...state, error: action.payload };
  },
  SET_LOADING: (state, action) => {
    return { ...state, loading: action.payload };
  },
  SET_STATE: (state, action) => {
    return { ...state, ...action.payload };
  }
};

// action = {type: 'SET_NAME', payload: {}}
const SitesReducer = (state = initialState, action = {}) => {
  if (obj[action.type]) {
    return obj[action.type](state, action);
  }
  return state;
};

export default SitesReducer;
