/** */
import axios from 'axios';

const myAxios = axios.create({
  baseURL:
    process.env.NODE_ENV === 'production' ? '/api' : 'http://localhost/hitlia', // 'https://mkGalaxy.com/api',
  timeout: 60000,
  headers: { 'Content-Type': 'application/json' }
});

export default myAxios;
